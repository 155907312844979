
export const years = [2023, 2024, 2025]

export const rounds = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]

export const jobs_ff = [{'test-dev':'bb'}, 'race-schedule', 'drivers', 'race-results', 'quali-results'
                    , 'driver-standings', 'constructor-standings', 'driver-stats']

export const jobs = [{'displayName':'test-dev', 'programName':'testdev'},
                     {'displayName':'race-schedule', 'programName':'loadrace'},
                     {'displayName':'drivers', 'programName':'loaddrivers'},
                     {'displayName':'race-results', 'programName':'loadraceresults'},
                     {'displayName':'quali-results', 'programName':'loadqualiresults'},
                     {'displayName':'driver-standings', 'programName':'loaddriverstandings'},
                     {'displayName':'constructor-standings', 'programName':'loadconstructorstandings'},
                     {'displayName':'driver-stats', 'programName':'loaddriverstats'}
]